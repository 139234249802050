import styled from 'styled-components';

// export const HomeContainer = styled.div`
//   position: relative;
//   width: 50%;
//   left: 10%;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   @media (max-width: 750px) {
//     width: 64%;
//     left: 0;
//     margin: 0 auto;
//   }

//   @media (max-width: 450px) {
//     width: 80%;
//   }
// `;

export const HomeContainer = styled.div`

`;