import React from 'react'
import { motion } from 'framer-motion';
import { Paragraph, ParagraphTitle, Title } from '../../styles/style'
import useScrollAnimation from '../../hooks/useScrollAnimation';
import { BitneuronTokenApiContainer, BitneuronTokenContainer, BitneuronTokenSection } from './bitneuronToken.styles';
import { bitneuronToken } from '../../constants/data';
import BitneuronTokenApi from '../BitneuronTokenApi/BitneuronTokenApi';

export default function BitneuronToken() {
    const { myRef, isVisible } = useScrollAnimation('bitneuron-token');
  return (
    <BitneuronTokenSection
        as={motion.div}
        id='bitneuron-token'
        ref={myRef}
        initial={{ opacity: 0, y: 100 }}
        animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
        transition={{ duration: 1 }}
    >
        <BitneuronTokenContainer>
            <Title>The Native Token of BrainTensor API</Title>
            <Paragraph color='var(--color-gray)' style={{textAlign: 'center'}}>
                The Bitneuron tokens will be generated by cortex  Holders & delegators and .ai users.
                The adoption curve of the tools built through the BrainTensor API will have an impact tokens.
            </Paragraph>
            <ParagraphTitle>
                The first platforms built API:
            </ParagraphTitle>

            <BitneuronTokenApiContainer>
                <BitneuronTokenApi bitneuronToken={bitneuronToken}/>
                <BitneuronTokenApi bitneuronToken={bitneuronToken}/>
            </BitneuronTokenApiContainer>
        </BitneuronTokenContainer>
        
    </BitneuronTokenSection>
  )
}
